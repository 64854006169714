.appBtn {
  border-radius: 6px;
  background-color: var(--color-primary);
  border: none;
  color: var(--color-white);
  &:focus {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
}
