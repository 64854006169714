body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-dark);
  overflow-x: hidden;
  font-family: heebo400;
}
@font-face {
  font-family: heebo400;
  src: url("https://api.epilog.world/public/assets/fonts/Heebo-Regular.ttf");
}
@font-face {
  font-family: ubuntu500;
  src: url("https://api.epilog.world/public/assets/fonts/Ubuntu-Medium.ttf");
}
@font-face {
  font-family: ubuntu700;
  src: url("https://api.epilog.world/public/assets/fonts/Ubuntu-Bold.ttf");
}

p {
  margin-bottom: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

html {
  font-size: 62.5%;
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 51%;
  }
}

// @media screen and (max-width: 471px) {
//   html {
//     font-size: 40%;
//   }
// }

:root {
  --color-primary: #112548;
  --color-white: #fff;
  --color-dark: #000000;
  --color-light: #8a8a8a;
  --color-info: #e9ebfc;
  --color-info-light: #e9ebfc54;
  --color-slate: #dedfe7;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

/*-----------------------------*/
/*          Ant Layout         */
/*-----------------------------*/
.ant-layout {
  .site-layout {
    min-height: 100vh;
    padding: 5.2rem 5.5rem;
    background: linear-gradient(180deg, #e9ebfc 0%, #ffffff 100%);
    .ant-layout-content {
      .title {
        font-size: 3.5rem;
        color: var(--color-primary);
        font-family: ubuntu700;
      }
      .dtl {
        font-size: 2rem;
        color: var(--color-light);
        font-family: ubuntu500;
      }
    }
  }
  .ant-layout-sider-children {
    padding: 4.8rem 1.2rem;
    background-color: var(--color-primary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .logo {
      display: block;
      margin: 0 auto 5.2rem;
      width: 60%;
    }
    .logout-btn {
      width: 3rem;
      margin: 0 auto;
      cursor: pointer;
    }
    .ant-menu-dark {
      background-color: transparent;
      font-size: 2rem;
      font-family: ubuntu500;
      color: var(--color-primary);
      .ant-menu-item {
        color: var(--color-white);
      }
      .ant-menu-item-selected {
        background-color: var(--color-white);
        color: var(--color-primary);
      }
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: var(--color-white);
      border-radius: 8px;
    }
    .ant-menu-dark {
      .ant-menu-item-selected {
        .ant-menu-item-icon,
        .ant-menu-dark,
        .ant-menu-item-selected,
        .anticon + span {
          color: var(--color-primary);
        }
      }
    }
  }
}

/*-----------------------------*/
/*          ant Table          */
/*-----------------------------*/
.tbl {
  .tbl-row {
    margin: 10px 0;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 2.4rem;
      font-family: ubuntu700;
      color: var(--color-dark);
    }
  }
  .ant-table-wrapper {
    .ant-spin-nested-loading {
      .ant-spin-container {
        .ant-table {
          background-color: transparent;
          .ant-table-container {
            border-radius: 8px;
            border: 1px solid var(--color-dark);
            background-color: transparent;
            overflow: hidden;
            .ant-table-content {
              table {
                border-radius: 8px;
                .ant-table-thead {
                  tr {
                    th {
                      font-size: 1.8rem;
                      color: var(--color-dark);
                      background: var(--color-slate);
                      border-bottom: 1px solid var(--color-dark);
                      padding: 1.3rem;
                      &:first-child {
                        border-top-left-radius: 8px;
                      }
                      &:last-child {
                        border-top-right-radius: 8px;
                      }
                    }
                  }
                  .ant-table-column-sorter-inner {
                    color: #979797;
                  }
                  .ant-table-column-sorter-up.active {
                    color: var(--color-primary);
                  }
                  .ant-table-column-sorter-down.active {
                    color: var(--color-primary);
                  }
                }
                .ant-table-tbody {
                  tr {
                    &:nth-child(odd) {
                      background-color: var(--color-info-light);
                    }
                    &:nth-child(even) {
                      background-color: var(--color-info);
                    }
                    .ant-table-cell {
                      padding: 1.4rem;
                      .table-drop-down {
                        padding: 1rem;
                        background-color: #fff;
                      }
                      .table-drop-down .heading {
                        font-size: 1.8rem;
                        font-weight: 500;
                        letter-spacing: 0.7px;
                      }
                      .table-drop-down ol li {
                        font-size: 1.6rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .ant-pagination {
          .ant-pagination-item {
            display: inline-block;
            min-width: 32px;
            height: 32px;
            margin-right: 8px;
            line-height: 30px;
            background-color: var(--color-info);
            border: none;
            border-radius: 4px;
            a {
              color: var(--color-primary);
              font-family: ubuntu500;
            }
          }
          .ant-pagination-item-active {
            background-color: var(--color-primary);
            a {
              color: var(--color-white);
            }
          }
          .ant-pagination-options {
            .ant-select-selector {
              background-color: var(--color-info);
              border-radius: 4px;
              border: none;
            }
          }
        }
      }
    }
  }
}

.login_container {
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .logo {
    display: flex;
    img {
      width: 20px;
    }
    p {
      font-size: 40px;
      text-transform: uppercase;
      color: #fff;
    }
  }
  .content {
    min-width: 350px;
    .common_input_main_div {
      .common_input_lable {
        font-size: 18px;
        margin-bottom: 10px;
        color: #fff;
      }
      .common_input_field {
        height: 50px;
        background-color: #eeeeee;
        border-radius: 5px;
        width: 100%;
        border: unset;
        outline: unset;
        padding: 0px 14px;
        font-size: 14px;
        color: #000000a6;
      }
    }
    .common_bttn {
      cursor: pointer;
      background-color: #34a853;
      border-radius: 6px;
      height: 50px;
      border: none;
      .common_bttn_p {
        text-transform: capitalize;
        font-size: 14px;
        color: #fff;
      }
      &:hover {
        transform: translateY(-2px);
        background-color: #23833d;
      }
      &:focus {
        background-color: #23833d;
      }
    }
  }
}
